import Vue from "vue/dist/vue.esm.js";

var menu = new Vue({
  el: "#main",
  data() {
    return { menu: false, langChooser: false };
  },
  watch: {
    menu: function (val) {
      const el = document.body;

      if (val) {
        el.classList.add("overflow-y-hidden");
        el.classList.add("md:overflow-y-auto");
      } else {
        el.classList.remove("overflow-y-hidden");
        el.classList.remove("md:overflow-y-auto");
      }
    },
  },
});
